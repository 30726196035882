import React from "react"
import { Routes, Route, Link, Outlet } from "react-router-dom"

import Api from "./Api"

import WishList from "./WishList"
//import MessageList from "./MessageList"
import Settings from "./Settings"
import Home from "./Home"
import Login from "./Login"
import Admin from "./Admin"
//import Link from "./Link"



class App extends React.Component {

  constructor(props) {
    super(props)
    this.api = new Api()
    this.state = { user: null, kk: null, fetching: true }
    this.fetchUser = this.fetchUser.bind(this)
    this.isAdmin = this.isAdmin.bind(this)
  }

  componentDidMount() {
    this.fetchUser(true)
    console.log('App:', this.props)
  }

  loggedIn() {
    return this.api.loggedIn()
  }

  fetchUser(force) {
    console.log(`App.fetchUser.force: ${force}`)
    var profile = this.api.getProfile()
    if (profile === null) {
      console.log("Api.fetchUser:NoTokenPayload")
      this.setState({ fetching: false })
      return
    }
    // eslint-disable-next-line
    if (force || (profile && !this.state.fetching && !this.state.user)) {
      if (force) { this.fetching = true }
      this.api.fetch(`/api/v1/user/${profile.mobile}`)
        .then(res => {
          var user = res
          if (res.kk) {
            this.api.fetch(`/api/v1/user/${res.kk}`)
              // eslint-disable-next-line
              .then(kkres => { this.setState({ fetching: false, kk: kkres, user: user }) })
          }
          else {
            // eslint-disable-next-line
            this.setState({ fetching: false, kk: null, user: user })
          }
          console.log("App.fetchUser:OK", res)
        })
        .catch(res => {
          console.log("App.fetchUser:Error", res)
          if (res.status === 401)
            this.api.logout()
          else
            this.setState({ failed: true })
        })
    }
  }

  isAdmin() {
    return this.state.user && this.state.user.permissions && this.state.user.permissions.includes("admin")
  }

  logout() {
    this.api.logout()
    this.setState({ user: null })
  }

  render() {
    // eslint-disable-next-line
    return (
      <Routes>
        <Route path="/" element={<Layout
          authed={this.state.user !== null}
          admin={this.isAdmin()}
          logout={this.api.logout}
        />}>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tos" element={<TOS />} />
          <Route path="/" extact element={<Home api={this.api} user={this.state.user} kk={this.state.kk} />} />
          <Route path="/settings" extact element={<Settings api={this.api} user={this.state.user} kk={this.state.kk} />} />
          <Route path="/wishes" extact element={<WishList api={this.api} user={this.state.user} kk={this.state.kk} />} />
          <Route path="/login" extact element={<Login api={this.api} refresh={this.fetchUser} />} />
          {this.isAdmin() && <Route path="/admin" extact element={<Admin api={this.api} user={this.state.user} kk={this.state.kk} />} />}
        </Route>
      </Routes >
    )
  }
}

function Layout(props) {
  return (
    <div className="App">
      <nav>
        <div>
          {props.authed && <div className="title">KrisKringle</div>}
          {props.authed && <div className="menu">
            <Link to='/'><i className="fas fa-home"></i></Link>
            <Link to='/wishes'><i className="far fa-list-alt"></i></Link>
            <Link to='/settings'><i className="fas fa-user-cog"></i></Link>
            {props.admin &&
              <Link to='/admin'><i className="fas fa-tools"></i></Link>
            }
            <button onClick={props.logout}><i className="fas fa-sign-out-alt"></i></button>
          </div>
          }
        </div>
      </nav>
      <article>
        <Outlet />
      </article >
      <Footer />
    </div >
  )
}

function Footer() {
  return (<footer><div><Link to="/tos">Terms of Service</Link> | <Link to="/privacy">Privacy statement</Link></div></footer>)
}

function Privacy() {
  return (<div><h3>Privacy Policy</h3>

    We are committed to respecting your privacy and protecting your personal information. This privacy policy outlines how we handle the information you provide when you interact with our services.

    <h3>Collection of Information</h3>

    We want to assure you that we do not collect or store any private or personally identifiable information (PII) without your explicit consent. Our services are designed to respect your privacy at all times.

    <h3>Usage of Information</h3>

    Any information you provide while interacting with our services is used solely for the purpose of facilitating your interaction and providing you with the best possible experience. We do not use this information for any other purpose, such as marketing or sharing with third parties.

    <h3>Cookies and Tracking</h3>

    We do not use cookies or any tracking mechanisms to collect information about your online activities. Our services are built to prioritize your privacy and security.

    <h3>Sharing of Information</h3>

    Since we do not collect private or personal information, there is no information to share with third parties.

    <h3>Data Security</h3>

    We take data security seriously. We implement industry-standard measures to protect any information you provide against unauthorized access, alteration, or disclosure.

    <h3>Children's Privacy</h3>

    Our services are not intended for individuals under the age of 13. We do not knowingly collect or store any information from children under 13.

    <h3>Changes to Privacy Policy</h3>

    We may update our privacy policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically.</div>)
}

function TOS() {
  return (<div><h2>Terms of Service</h2>

    These Terms of Service ("Terms") govern your use of KK ("the Service") provided by Ricardo Salinas ("the Company"). By using the Service, you agree to abide by these Terms. If you do not agree with these Terms, please refrain from using the Service.

    <h3>1. Use of the Service</h3>

    The Service is provided for your personal and non-commercial use only. You agree not to use the Service for any unlawful purposes or in any way that violates these Terms.

    <h3>2. User Content</h3>

    You are responsible for any content you post or submit on the Service. By using the Service, you grant the Company a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, publish, distribute, and display your content.

    <h3>3. Intellectual Property</h3>

    All content and materials provided through the Service, including but not limited to text, images, logos, and software, are owned by or licensed to the Company. You may not reproduce, distribute, modify, or create derivative works based on the Service's content without the Company's explicit consent.

    <h3>4. Privacy</h3>

    Your use of the Service is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information.

    <h3>5. Termination</h3>

    The Company reserves the right to terminate your access to the Service at any time without notice or liability for any reason, including if you violate these Terms.

    <h3>6. Limitation of Liability</h3>

    The Company is not liable for any damages, losses, or claims arising out of your use of the Service. The Service is provided on an "as is" and "as available" basis without any warranties.

    <h3>7. Indemnification</h3>

    You agree to indemnify and hold the Company harmless from any claims, losses, or liabilities arising from your use of the Service or any violation of these Terms.

    <h3>8. Changes to Terms</h3>

    The Company may update these Terms from time to time. It's your responsibility to review the Terms periodically. Continued use of the Service after any changes signifies your acceptance of the updated Terms.

    <h3>9. Governing Law</h3>

    These Terms are governed by and construed in accordance with the laws of Victoria, Australia, without regard to its conflict of law principles.

    <h3>10. Contact</h3>

    If you have any questions or concerns about these Terms, please contact us at ricardo+kk@salinas.id.au.</div>)
}

export default App;
