import React from "react"

export default class SendingButton extends React.Component {

  render() {
    return (
      <button
        style={this.props.style || {}}
        className={`pure-button pure-button-primary ${this.props.sending ? "pure-button-disabled" : ""}`}
        onClick={this.props.onClick}
      >
        {this.props.sending ?
          <div>Sending...</div>
          :
          this.props.text
        }
      </button>
    )
  }
}
