import decode from 'jwt-decode'
import 'whatwg-fetch'

export default class Api {
  // Initializing important variables
  constructor(domain) {
    this.domain = window.location.hostname === 'localhosts' ? `http://localhost:5000` : `https://kk-api.salinas.id.au`
    this.fetch = this.fetch.bind(this) // React binding stuff
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.refreshToken = this.refreshToken.bind(this)
    this.checkToken = this.checkToken.bind(this)
    this.timer = window.setInterval(this.checkToken, 25000)
    this.checkToken()
  }

  checkToken() {
    if (this.getToken() === null) {
      clearInterval(this.timer)
      return
      //this.logout()
    }
    var exp = this.getTokenExpiresIn()
    if (exp < 0) {
      this.logout()
    }
    else if (exp < 60) {
      this.refreshToken()
    }
    else {
    }
  }

  login() {
    // Get a token from api server using the fetch api
    return this.fetch(`/api/login`, {
      method: 'GET'
    }).then(res => {
      return res.url
    })
  }

  refreshToken() {
    try {
      if (this.getToken()) {
        this.fetch(`/api/refresh`, { method: "POST" })
          .then(res => {
            if (res.token) {
              this.setToken(res.token)
              return res.token
            }
          })
          .catch(resp => console.log('refreshToken:rejected', resp))
      }
    }
    catch (e) {
      console.log('refreshToken:catch:', e)
    }
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token) // handwaiving here
    //return this.testToken()
  }

  isTokenExpired(token) {
    try {
      const claims = decode(token)
      if (claims.exp < ((new Date()).getTime() / 1000)) {
        console.log('Expired token - removed JWT')
        return true
      }
      return false
    }
    catch (err) {
      return true;
    }
  }

  setToken(token) {
    // Saves user token to localStorage
    localStorage.setItem('jwt', token)
    clearInterval(this.timer)
    this.timer = window.setInterval(this.checkToken, 25000)
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('jwt')
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('jwt');
    window.location = '/login'
  }

  getTokenExpiresIn() {
    try {
      let data = decode(this.getToken())
      return Math.round(data.exp - ((new Date()).getTime() / 1000))
    }
    catch (e) {
      return -1
    }
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    try {
      let data = decode(this.getToken())
      return data
    }
    catch (e) {
      console.log(`Api.getProfile(${e})`)
      return null
    }
  }

  fetch(url, options) {
    options = options || {}
    // performs api calls sending the required authentication headers
    const headers = {
      'Content-Type': 'application/json'
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.getToken()
    }

    options.headers = headers

    console.log(`fetching ${this.domain}${url}`)

    return fetch(
      `${this.domain}${url}`,
      options
    )
      .then(this._checkStatus)
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
      return Promise.resolve(response.json())
    } else {
      console.log('_checkStatus rejection:', response)
      return Promise.reject(response)
    }
  }
}
