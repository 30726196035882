import React from "react"

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = { password: '', password2: ''};

    this.handleChange = this.handleChange.bind(this);
    this.postPassword = this.postPassword.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  postPassword(e) {
    e.preventDefault()
    if (this.state.password.length && this.state.password !== this.state.password2) {
      return false;
    }

    this.props.api.fetch(
      '/api/password',
      {
        method: 'POST',
        body: JSON.stringify({"password": this.state.password})
      }
    ).then(res => {
      this.props.api.fetch(
        `/api/v1/user/${this.props.user.mobile}`,
        {
          method: 'POST',
          body: JSON.stringify({"hash": res.hash})
        }
      ).then(res => this.setState({user: res}))
    })
  }

  render() {
    return (
      <div className="Settings">
        <h1>Settings</h1>

        <form className="password-reset pure-form pure-form-stacked">
          <fieldset>
            <legend>Password reset</legend>
            <input
              className="text-field"
              placeholder="Password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
          { (this.state.password.length > 0 && this.state.password2.length > 0) && (this.state.password !== this.state.password2) && <span style={{color: "red"}}>Passwords need to match</span> }
            <input
              className="text-field"
              placeholder="Password Again"
              name="password2"
              type="password"
              value={this.state.password2}
              onChange={this.handleChange}
            />
            <button className="pure-button pure-button-primary" onClick={this.postPassword}>Update password</button>
          </fieldset>
        </form>
      </div>
    )
  }
}

export default Settings
