import React from "react"

import SelectUser from "./SelectUser"
import Moment from 'react-moment'
import SendingButton from "./SendingButton"
import 'moment-timezone'

class Admin extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      users: [],
      messaging: false,
      resetting: false,
      drawing: false,
      undrawing: false
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    if (this.props.api.getProfile().permissions.includes("admin"))
      this.fetchUsers()
    //console.log(this.state)
  }

  fetchUsers = () => {
    this.props.api.fetch(`/api/v1/users`)
    .then(res => this.setState({users: res}))
    .catch(res => {
      console.log("Admin.fetchUsers:", res)
      if (res.status === 401)
        this.props.api.logout()
      else
        this.setState({failed: true})
    })
  }

  resetPassword = (e) => {
    console.log(`state before /rpc/resetpassword`, this.state)
    this.setState({resetting: true})
    this.props.api.fetch(
      `/rpc/resetpassword`,
      {
        method: 'POST',
        body: JSON.stringify({users: this.state.reset})
      }
    )
    .then(res => {
      this.setState({resetting: false})
      console.log("Admin.resetPassword:", res)
    })
    .catch(res => {
      this.setState({resetting: false})
      console.log("Admin.resetPassword:", res)
    })
    e.preventDefault()
    return false
  }

  setResetUser = (value) => {
    if (value === "all") {
      this.setState({reset: this.state.users.map(u => u.mobile)})
    }
    else {
      this.setState({reset: [value]})
    }
  }

  setText = (e) => {
    this.setState({text: e.target.value})
    this.state.texterror && this.setState({texterror:''})
  }

  setRecipients = (value) => {
    if (value === 'all') {
      this.setState({recipients: this.state.users.map(u => u.mobile)})
    }
    else {
      this.setState({recipients: [value]})
    }
  }

  messageValid = () => {
    var ok = true
    if (!this.state.text || this.state.text === "") {
      this.setState({texterror: "Need a message to send"})
      ok = false
    }
    return ok
  }

  message = (e) => {
    e.preventDefault()
    if (!this.messageValid())
      return false

    this.setState({messaging: true})
    console.log("Admin.message.state", this.state)
    this.props.api.fetch(
      `/rpc/message`,
      {
        method: 'POST',
        body: JSON.stringify({
          text: this.state.text,
          recipients: this.state.recipients
        })
      }
    )
    .then(res => {
      this.setState({messaging: false})
      console.log("Admin.message:OK", res)
    })
    .catch(res => {
      this.setState({messaging: false})
      console.log("Admin.message:Error", res)
    })
    return false
  }

  draw = (e) => {
    e.preventDefault()
    console.log("Admin.draw.state",this.state)
    this.setState({drawing: true})
    this.props.api.fetch(
      `/rpc/draw`,
      {
        method: 'POST',
        body: JSON.stringify({})
      }
    )
    .then(res => {
      this.fetchUsers()
      this.setState({drawing: false})
    })
    .catch(res => {
      this.setState({drawing: false})
      console.log("Admin.draw:result:Error", res)
    })
    return false
  }

  undraw = (e) => {
    e.preventDefault()
    console.log("Admin.undraw.state",this.state)
    this.setState({undrawing: true})
    this.props.api.fetch(
      `/rpc/undraw`,
      {
        method: 'POST',
        body: JSON.stringify({})
      }
    )
    .then(res => {
      this.fetchUsers()
      this.setState({undrawing: false})
    })
    .catch(res => {
      this.setState({undrawing: false})
      console.log("Admin.undraw:result:Error", res)
    })
    return false
  }

  render() {
    const all = [{name: '-'}, {name: 'All', mobile: 'all'}]
    const timetodraw = !this.state.users.reduce((prev, u) => { return prev || u.kk }, false)
    const users_plus_all = all.concat(this.state.users)
    return (
        <div className="Admin">
          <h1>Admin</h1>
            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>Members</legend>
                <ul className="members">
                  {
                    this.state.users &&
                    this.state.users.map(u => {
                      return (
                        <li key={u.mobile}>
                          <span className="name">{u.name}</span>
                          <span className="date">
                            { u.authed &&
                              <span>Last seen <Moment fromNow local>{u.authed}</Moment></span>
                            }
                          </span>
                          <span className="wishes">
                            {'wishes' in u ? u.wishes.length : "no"} wishes. &nbsp;
                            {u.wishes ? <span className="date">(Updated <Moment fromNow>{u.wishes.reduce((a,b)=>{return (b.date>a.date)?b:a}).date}</Moment>)</span>: ""}
                          </span>

                        </li>
                      )
                    })
                  }
                </ul>
              </fieldset>
            </form>
            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>Reset password</legend>
                <SelectUser users={ users_plus_all } name={ 'users' } onChange={this.setResetUser}/>
                <SendingButton text="Reset" onClick={this.resetPassword} sending={this.state.resetting} />
              </fieldset>
            </form>
            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>Send a broadcast</legend>
                <textarea onChange={this.setText}></textarea>
                <span style={{color: 'red'}}>{this.state.texterror || ""}</span>
                <SelectUser users={ users_plus_all } name={ 'users' } onChange={this.setRecipients} />
                <SendingButton text="Send" onClick={this.message} sending={this.state.messaging} />
              </fieldset>
            </form>
            <form className="pure-form pure-form-stacked">
              <fieldset>
                <legend>Draw names</legend>
                { timetodraw ?
                  <SendingButton text="Draw" onClick={this.draw} sending={this.state.drawing} />
                  :
                  <SendingButton text="Undraw" onClick={this.undraw} sending={this.state.undrawing} style={{background: "rgb(202, 60, 60)"}} />
                }
              </fieldset>
            </form>

        </div>
    )
  }
}

export default Admin
