import React from "react"

export default class SelectUser extends React.Component {

  constructor(props) {
    super(props)
    this.selectionMade = this.selectionMade.bind(this)
  }

  selectionMade(e) {
    this.props.onChange(e.target.value)
  }

  render() {
    return (
      <select name={this.props.name} className={this.props.class} onChange={this.selectionMade}>
        { this.props.users.map(u => { return <option key={u.mobile} value={u.mobile}>{u.name}</option> })
        }
      </select>
    )
  }
}
