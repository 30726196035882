import React, { Component } from 'react'
import './Login.css'

class Login extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = { mobile: '', password: '', submitting: window.location.href.includes("#"), data: window.location.href.includes("#") ? JSON.parse(atob(window.location.href.split("#")[1])) : null };
        console.log(this.state)
    }

    componentWillMount() {
        // if (this.props.api.loggedIn())
        //     this.props.history.replace('/');

        if (this.state.data) {
            this.props.api.setToken(this.state.data.token)
            this.props.refresh()
            window.location = '/'
        }
    }

    render() {
        return (
            this.state.submitting ?
                <i className="fas fa-sleigh loading-spin"></i>
                :
                <div class="full">
                    <div className="center Login">
                        <div className="card">
                            <h1>KrisKringle</h1>
                            <form>
                                <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-login="salinaskk_bot" data-size="large" data-onauth="onTelegramAuth(user)" data-request-access="write"></script>
                            </form>
                        </div>
                    </div>
                </div>
        );
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();

        this.props.api.login()
            .then(res => {
                window.location = res
            })
    }
}

export default Login;
