import React from "react"
import Moment from 'react-moment'
import 'moment-timezone'

//const ICONS = ['fas fa-sleigh','fas fa-candy-cane','fas fa-holly-berry','fas fa-gifts']

class WishList extends React.Component {

  constructor(props) {
    super(props);
    this.state = { wish: '', sending: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ sending: 'pure-button-disabled' })
    this.props.api.fetch(
      '/api/v1/wish',
      {
        method: 'POST',
        body: JSON.stringify({ "text": this.state.wish })
      }
    ).then(res => this.props.refresh(true))
      .catch(err => {
        this.setState({ sending: '' })
        console.log('Failed to save wish', err)
      })
  }

  handleChange(e) {
    this.setState({
      wish: e.target.value
    })
  }

  handleDelete(e) {
    e.preventDefault()
    const id = e.target.getAttribute('data-id')
    this.props.api.fetch(
      `/api/v1/wish`,
      {
        method: 'DELETE',
        body: JSON.stringify({ "id": id })
      }
    ).then(res => this.props.refresh(true))
      .catch(err => {
        console.log('Failed to delete wish', err)
      })
  }

  render() {
    return (
      <div className="WishList">
        <h1>Wishes</h1>
        {this.props.user && this.props.user.wishes &&
          <form className="pure-form pure-form-stacked">
            <fieldset>
              <legend>Wishlist</legend>
              {
                this.props.user.wishes.map(
                  (m) => {
                    return (
                      <div className="wish-item" key={m.id}>
                        <span className="date">(Added <Moment fromNow local>{m.date}</Moment>)</span>
                        <button
                          className="pure-button"
                          style={{ fontSize: '70%', background: 'rgb(202, 60, 60)', color: 'white' }}
                          data-id={m.id}
                          onClick={this.handleDelete}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                        <span className="text">{m.text}</span>
                      </div>
                    )
                  })
              }
            </fieldset>
          </form>
        }
        <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit}>
          <fieldset>
            <legend>Add to the list</legend>
            <textarea name='wish' rows={"10"} value={this.state.wish} className="pure-input-1-2" onChange={this.handleChange} placeholder="Your wish"></textarea>
            <button className={`pure-button pure-button-primary ${this.state.sending}`}>{this.state.sending ? <p>Saving...</p> : "Save"}</button>
          </fieldset>
        </form>
      </div>
    )
  }
}

export default WishList
