import React from "react"

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = props
    console.log('Home:', this.state)
  }

  componentDidMount() {
    console.log(this.props)
  }

  render() {

    // if (!this.props.user) {
    //   window.location.href = '/login'
    //   return (<div />)
    // }

    return (
      <div className="Home">
        {!this.props.user ?
          <i className="fas fa-sleigh loading-spin"></i>
          :

          this.props.kk ?
            <KKDrawn kk={this.props.kk} />
            :
            <NotDrawn />

        }
      </div>
    )
  }
}

export default Home

function NotDrawn() {
  return (<p>KKs have not been drawn yet. <br /> Make sure you have done your wishlist. &nbsp; <i className="far fa-list-alt"></i></p>)
}

function KKDrawn(props) {
  return (<div><p>This year you have:</p>
    <h3>{props.kk.name}</h3>
    <WishList wishes={props.kk.wishes} />
  </div>
  )
}

function WishList(props) {
  var icons = ['fas fa-sleigh', 'fas fa-candy-cane', 'fas fa-holly-berry', 'fas fa-gifts']
  if (!props.wishes.length)
    return (<span>No wishlist yet, try sending them a message. <i className="fas fa-comments"></i></span>)
  return (
    <form className="pure-form pure-form-stacked">
      <fieldset>
        <legend>Wishlist</legend>
        <ul className="fa-ul">
          {props.wishes.map((i, n) => (
            <li key={i.id}>
              <span className="fa-li">
                <i className={icons[n % icons.length]}></i>
              </span>
              {i.text}
            </li>
          ))
          }
        </ul>
      </fieldset>
    </form>
  )
}